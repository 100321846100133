main {
	padding: 0px 150px;
}

@media (max-width: 1080px) {
	main {
		padding: 0px 100px;
	}
}
@media screen and (max-width: 768px) {
	main {
		padding: 0px 50px;
	}
}
@media screen and (max-width: 400px) {
	main {
		padding: 0px;
	}
}

.loader {
	display: inline-block;
	width: 100px;
	height: 100px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.loader:after {
	content: " ";
	display: block;
	width: 100px;
	height: 100px;
	margin: 1px;
	border-radius: 50%;
	border: 5px solid #fff;
	border-color: var(--dark-black) var(--cyan) var(--dark-black) var(--cyan);
	animation: loader 2s linear infinite;
}

@keyframes loader {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
