:root {
	--white: white;
	--black: #17252a;
	--light-black: #243941;
	--grey: rgb(202, 202, 202);
	--cyan: #00ffff;
	--button-rgba: rgb(0, 255, 255, 0.1);
	--delay: 2s;
	--dark-black: #152227;
	/* --dark-black: #131d20; */
}
@font-face {
	font-family: 'Pacifico';
	font-style: normal;
	font-weight: 400;
	src: local('Pacifico Regular'), local('Pacifico-Regular'),
		url(https://fonts.gstatic.com/s/pacifico/v12/FwZY7-Qmy14u9lezJ-6H6MmBp0u-.woff2) format('woff2');
	font-display: swap;
}
*,
*::before,
*::after {
	box-sizing: border-box;
}

body {
	margin: 0;
	padding: 0;
	font-family: 'Baloo Tammudu 2', cursive;
	background: var(--black);
	line-height: 1.3;
}
a {
	text-decoration: none;
}
img {
	max-width: 100%;
	display: block;
}
